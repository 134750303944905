<template>
  <!-- 我的是服务商  -->
  <div class="serverPage Mt24">
    <div class="filter">
      <div class="f-sort">
        <span @click="selectFun(0)" :class="querys === 0 ? 'act' : ''">
          进行中的任务
        </span>
        <span @click="selectFun(5)" :class="querys === 5 ? 'act' : ''"
          >已完成的任务</span
        >
        <span @click="selectFun(6)" :class="querys === 6 ? 'act' : ''"
          >已撤销的任务</span
        >
      </div>
    </div>
    <div v-if="total === 0"><el-empty description="暂无任务" /></div>
    <div v-else class="usTaskLis">
      <!-- {{ taskList }} us:jf甲方，yf乙方 -->
      <us-task-li
        v-for="(ibm, ibx) in taskList"
        :key="ibx"
        :itm="ibm"
        us="yf"
        @open="taskOpen"
        :category="category"
      ></us-task-li>
    </div>
    <div class="pagination">
      <el-pagination background layout="prev, pager, next" :total="total" />
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { getUsTaskServer } from '@/api/task'
import { useRouter } from 'vue-router'
import { sign } from '@/utils/storage'
import usTaskLi from '@/components/Task/usTaskLi'
const querys = ref(0) // 列表查询条件
const selectFun = s => {
  querys.value = s
  getData()
}

// 列表
const pages = ref(1)
const total = ref(null)
const taskList = ref([])
const getData = async () => {
  var datas = await getUsTaskServer(
    sign({ state: querys.value, page: pages.value, size: 10 })
  )
  taskList.value = datas.list
  total.value = datas.total
}
getData()
const router = useRouter()
const taskOpen = ss => {
  router.push(`/mytaskshow?d=${ss.taskID}`)
}
</script>

<style lang="scss" scoped></style>
