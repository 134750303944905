<template>
  <div class="taskLi mli5" @click="taskLiClik(itm)">
    <div class="leftPrice">
      <div class="budgetBox">
        <span class="budget">
          <strong>{{ itm.integral }}</strong>
        </span>
        <span class="budgetTag">任务预算</span>
        <p class="bzj">
          保证金:<strong>{{ itm.margin }}</strong>
        </p>
      </div>
    </div>
    <div class="taskContent">
      <div class="tit">
        <strong>{{ itm.taskName }}</strong>
      </div>
      <!-- <div class="text">{{ itm.taskName }}</div> -->
      <div class="text" v-html="itm.acceptanceCriteria"></div>
      <div>
        <ul class="parameter">
          <li class="time">
            时间：<span>
              {{ itm.planStartDate }} 至 {{ itm.planEndDate }}
            </span>
          </li>
          <li>
            所属行业：<span>{{ itm.fieldLabel }} </span>
          </li>
          <li class="time">
            <template v-if="props.us === 'jf'">
              <!-- 甲方 -->
              开始时间：<span>{{ itm.actualStartDate }}</span>
            </template>
            <template v-else>
              开始时间：<span>{{ itm.startDatetime }}</span>
            </template>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps } from 'vue'
// import { useRouter } from 'vue-router'
// import { useStore } from 'vuex'
const emits = defineEmits(['open'])
const props = defineProps({
  itm: {
    type: Object,
    required: { title: 'aaa' }
  },
  category: {
    type: Array,
    required: []
  },
  us: {
    type: String,
    required: ''
  }
})
console.log('TaskLi --- ', props)
const taskLiClik = its => {
  emits('open', its)
}
</script>

<style lang="scss" scoped></style>
