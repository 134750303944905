<template>
  <!-- 我的任务 -->
  <div class="" style="">
    <div class="Ptit tabs">
      <strong class="tit">我的任务</strong>
      <ul>
        <li
          :class="usTskAct === 'server' ? 'act' : ''"
          @click="usTskAct = 'server'"
        >
          我是服务商
        </li>
        <li
          :class="usTskAct === 'employer' ? 'act' : ''"
          @click="usTskAct = 'employer'"
        >
          我是雇主
        </li>
      </ul>
      <span class="rset"></span>
    </div>
    <div>
      <div v-if="usTskAct === 'server'" class="">
        <server></server>
      </div>
      <div v-else class="">
        <employer></employer>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
// import { sign } from '@/utils/storage'
// import { uploadImg, postCertification, getCertificationShow } from '@/api/user'
// import { ElMessage, ElMessageBox } from 'element-plus'
// import { UploadProps, UploadUserFile } from 'element-plus'
// import { useRoute, useRouter } from 'vue-router'
import server from './components/Server'
import employer from './components/Employer'
const usTskAct = ref('server')
</script>

<style lang="scss" scoped></style>
